import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import withAuthorization from '../components/Session/withAuthorization'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import cookie from 'react-cookies'
import { navigate } from 'gatsby'

const ProductPageContent = ({ data }) => (
  <>
    <Helmet title={`${data.title}`} />
    <div className="container productdetails">
      <h1>{data.title}</h1>
      <div
        dangerouslySetInnerHTML={{ __html: data.body.childMarkdownRemark.html }}
      />
    </div>
  </>
)

const authCondition = authUser => !!authUser
const mapStateToProps = state => {
  return {
    users: state,
  }
}

const ProductPagePost = compose(
  connect(
    mapStateToProps,
    null
  ),
  withAuthorization(authCondition)
)(ProductPageContent)

class ProductPost extends Component {
  handleNewComment(comment) {
    console.log(comment.text)
  }
  constructor() {
    super()
    this.state = {
      route: '',
    }
  }

  componentDidMount() {
    const saveData = cookie.load('authUser')
    if (saveData) {
      this.setState({ route: '' })
    } else {
      this.setState({ route: navigate('/') })
    }
  }

  render() {
    const saveData = cookie.load('authUser')
    return (
      <Fragment>
        {saveData ? (
          <Layout>
            <ProductPagePost data={this.props.data.contentfulProduct} />
          </Layout>
        ) : (
          this.state.route
        )}
      </Fragment>
    )
  }
}

ProductPost.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ProductPost

export const pageQuery = graphql`
  query productPostQuery($slug: String!) {
    contentfulProduct(slug: { eq: $slug }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
